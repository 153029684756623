// Image.js
import React, { useState, useEffect, useRef } from 'react';

const Image = ({ src, alt }) => {
    const [isLoading, setIsLoading] = useState(true);
    const imgRef = useRef();

    useEffect(() => {
        const img = new window.Image();
        img.src = src;
        img.onload = () => setIsLoading(false);

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    imgRef.current.src = src;
                    observer.disconnect();
                }
            });
        });

        if (imgRef.current) {
            observer.observe(imgRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [src]);

    return (
        <div className="w-full h-full object-contain">
            {isLoading ? (
                <div className="w-full h-full bg-gray-200"></div> // 占位图
            ) : (
                <img ref={imgRef} src={src} alt={alt} className="w-full h-full object-contain rounded-[4px] bg-[#ededed] dark:bg-[#1f1f1f] " />
            )}
        </div>
    );
};

export default Image;