// Home.js
import React from 'react';
import { Helmet } from 'react-helmet';
import Work from '../component/work';
import Main from '../component/main';

function Home() {
  return (
    <div className="max-w-[1200px] p-[24px] mx-auto items-center">
      <Helmet>
        <title>Praticle Design | 微光子視覺整合行銷設計</title>
        <meta name="description" content="Particle integrated marketing & graphic design" />
        <meta name="keywords" content="Design, 設計, 台灣設計, 廣告, 品牌, 行銷設計, 包裝設計, 微光子視覺, Joshua Shiue" />
        <meta name="author" content="Particle Design" />
      </Helmet>
      <Main />
      <Work />
    </div>
  );
}

export default Home;