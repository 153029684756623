import React from 'react';
import { useTranslation } from 'react-i18next';

const Main = () => {
    const { t } = useTranslation();

    return (
        <main className='text-left pt-[42px]'>
            <div>
                <div className='text-[18px] font-bold dark:text-[#e7e7e7]'>
                    {t('Praticle Design')}
                </div>
                <div className='text-[14px] font-extralight pt-[4px] text-[#3D3D3D] dark:text-[#a3a3a3] '>
                    {t('Particle integrated marketing & graphic design')}
                </div>
            </div>
            <div>
                <div className='text-[56px] font-extralight max-w-[600px] py-[80px] leading-tight dark:text-[#e7e7e7]'>
                    {t('Joyful creativity, Powerful advancement')}
                </div>
                <div className='space-y-[24px] font-light text-[16px] max-w-[700px]  pt-[12px] pb-[100px] text-[#3D3D3D] dark:text-[#a3a3a3] leading-relaxed'>
                    <div>
                    {t('Because of the daring wisdom and courage')}<br/>
                    {t('Again, the Particle Design stands up and start off')}<br/>
                    {t('Keep on sparkling, as the meaning of the logo: Joyful creativity, Powerful advancement')}<br/>
                    </div>
                    <div>
                    {t('The only belief is to invite more people to enjoy the joy by the creative,')}<br/>
                    {t('and the comfort by the visual')}<br/>
                    {t('Even a particle can shine up, and it must be!')}<br/>
                    {t('The naming of the “Particle”')}<br/>
                    {t('Is from one of my name “Huei”')}<br/>
                    {t('“Huei”, the splendor. No grey, no dark.')}<br/>
                    {t('And “Guan-Tze”, the light, brings energy and movement')}<br/>
                    {t('To carry the thoughts of art')}<br/>
                    {t('To distribute the evolution and strike by the joyful creativity')}<br/>
                    </div>
                    <div>
                    {t('To recall us the warmth of the long-forgotten light and color')}<br/>
                    {t('To bring the limitless possibility, and well-present by any details')}<br/>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Main;