// App.js
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './component/header'; 
import Home from './page/Home';
import About from './page/About';

function App() {
  return (
    <Router>
      <div className=' bg-[#fff] dark:bg-[#000000]'>
          <Header/> 
          <Routes >
            <Route path="/about" element={<About />} />
            <Route path="/" element={<Home />} />
          </Routes>
      </div>
    </Router>
  );
}

export default App;