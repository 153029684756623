import axios from 'axios';
import NodeCache from 'node-cache';

const myCache = new NodeCache({ stdTTL: 100, checkperiod: 120 });
const CACHE_KEY = 'works';
const CACHE_TIMESTAMP_KEY = 'works_timestamp';
const CACHE_TTL = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

const getWorksFromAirtable = async () => {
    const cachedWorks = myCache.get(CACHE_KEY);
    const cachedTimestamp = localStorage.getItem(CACHE_TIMESTAMP_KEY);
    const now = Date.now();

    // Check if cached data is still valid
    if (cachedWorks && cachedTimestamp && (now - cachedTimestamp < CACHE_TTL)) {
        return cachedWorks;
    } else {
        try {
            const response = await axios.get('https://api.airtable.com/v0/appix6rG6CIUyfoBo/Works', {
                headers: {
                    'Authorization': 'Bearer patpFkBXc1LI9G6wI.59c44d6a246f395e66b4ac82851c66d75f0bc45802746dad1e061932388c1bb5'
                }
            });

            if (response.status !== 200) {
                throw new Error(`Unexpected response status: ${response.status}`);
            }

            if (!response.data || !response.data.records) {
                throw new Error('No records found');
            }

            const works = response.data.records
                .filter(record => record.fields && record.fields.Status !== 'Hidden')
                .map(record => {
                    const images = record.fields.Images ? record.fields.Images.map(image => image.url) : [];
                    return {
                        name: record.fields.Name || 'No Name',
                        description: record.fields.Describe || 'No Description',
                        status: record.fields.Status || 'No Status',
                        images: images,
                        type: record.fields.Type || 'No Type'
                    };
                });

            // Cache works in memory
            const cacheSuccess = myCache.set(CACHE_KEY, works);
            if (!cacheSuccess) {
                throw new Error('Failed to set cache');
            }

            // Cache works and timestamp in localStorage
            localStorage.setItem(CACHE_KEY, JSON.stringify(works));
            localStorage.setItem(CACHE_TIMESTAMP_KEY, now.toString());

            return works;
        } catch (error) {
            console.error('Error fetching data from Airtable:', error.message);

            // Try to get data from localStorage if available
            const localStorageWorks = localStorage.getItem(CACHE_KEY);
            if (localStorageWorks) {
                return JSON.parse(localStorageWorks);
            }

            return [];
        }
    }
};

export default getWorksFromAirtable;