// about.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

function About() {
  const { t } = useTranslation();

  return (
    <div className='mx-auto max-w-[1200px]  p-[24px] min-h-screen'>
      <Helmet>
        <title>Contact Me | 聯繫我</title>
        <meta name="description" content="Learn more about Particle Design, integrated marketing & graphic design." />
        <meta name="keywords" content="Particle Design, marketing, graphic design, Joshua Shiue" />
        <meta name="author" content="Particle Design" />
      </Helmet>
      <div className='pt-[42px]'>
          <div className='text-[18px] font-bold dark:text-[#e7e7e7]'>
            {t('Particle TW')}
          </div>
          <div className='text-[14px] font-extralight pt-[4px] text-[#3D3D3D] dark:text-[#a3a3a3] '>
            {t('Particle integrated marketing & graphic design')}
          </div>
      </div>
      
      <div className='pt-[100px] font-[16px]  text-[#3D3D3D] dark:text-[#858585] leading-relaxed max-w-[500px] '>
        <div className='text-[32px] font-extralight dark:text-[#e7e7e7] pb-[4px] ' >{t('Joshua Shiue')}</div>
        <div className='pb-[12px]'>{t('3F., No.253, Deyu Rd., Pingzhen Dist., Taoyuan City 324, Taiwan, Republic of China')}</div>

        <div>{t('Cell phone')}: <span className='dark:text-[#e3e3e3] pl-[2px]'>{t('03-428-3302 · 0912-283-302')}</span></div>
        <div>{t('Email')}: <span className='dark:text-[#e3e3e3] pl-[2px]'><a className='text-[#4682e2]' href='mailto:particle20081010@amail.com'>{t('particle20081010@amail.com')}</a></span></div>
        <div>{t('Line')}: <span className='dark:text-[#e3e3e3]  pl-[2px]'>{t('particle20081010')}</span></div>
        <div>{t('Facebook')}: <span className='dark:text-[#e3e3e3]  pl-[2px]'><a className='text-[#4682e2]' href='https://www.facebook.com/joshua.shiue'>{t('Joshua Shiue')}</a> / <a className='text-[#4682e2]' href='https://www.facebook.com/particle.tw'>{t('Particle Design')}</a></span></div>
        <div>{t('Instagram')}: <span className='dark:text-[#e3e3e3]  pl-[2px]'><a className='text-[#4682e2]' href='https://www.instagram.com/joshua_shiue/'>{t('Joshua_Shiue')}</a></span></div>
        <div>{t('YouTube')}: <span className='dark:text-[#e3e3e3]  pl-[2px]'><a className='text-[#4682e2]' href='https://www.youtube.com/@user-kc7cn9et2i'>{t('微光子視覺整合行銷設計')}</a></span></div>
      </div>
    </div>
  );
}

export default About;