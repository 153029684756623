// ThemeToggle.js
import React, { useState, useEffect } from 'react';
import { Moon, Sun } from 'lucide-react';

const ThemeToggle = () => {
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedTheme = localStorage.getItem('isDarkMode');
        return savedTheme ? JSON.parse(savedTheme) : false;
    });

    useEffect(() => {
        if (isDarkMode) {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
        localStorage.setItem('isDarkMode', JSON.stringify(isDarkMode));
    }, [isDarkMode]);

    return (
        <label className="relative inline-flex items-center cursor-pointer">
            <input 
                type="checkbox" 
                checked={isDarkMode} 
                onChange={() => setIsDarkMode(!isDarkMode)} 
                className="sr-only peer"
            />
            <div className="w-11 h-6 dark:bg-[#313131] bg-[#e2e2e2] rounded-full transition-all duration-300">
                <div className={` items-center justify-center flex absolute top-0.5 left-0.5 w-5 h-5 bg-[#fff] dark:bg-[#6a6767] rounded-full transform transition-transform duration-300 ${isDarkMode ? 'translate-x-full' : ''}`}>
                    {isDarkMode ? <Moon size={14} className="text-[#282828] dark:text-[#f3f3f3]" /> : <Sun size={14} className="text-[#f5a43b]" />}
                </div>
            </div>
        </label>
    );
}

export default ThemeToggle;