// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const userLang = localStorage.getItem('userLang') || 'en';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translations: {
        "Studio Introduction": "Studio Introduction",
        "Contact Me": "Contact Me",
        "Praticle Design": "Praticle Design",
        "Particle integrated marketing & graphic design": "Particle integrated marketing & graphic design",
        "Joyful creativity, Powerful advancement": "Joyful creativity, Powerful advancement",
        "Because of the daring wisdom and courage": "Because of the daring wisdom and courage",
        "Again, the Particle Design stands up and start off": "Again, the Particle Design stands up and start off",
        "Keep on sparkling, as the meaning of the logo: Joyful creativity, Powerful advancement": "Keep on sparkling, as the meaning of the logo: Joyful creativity, Powerful advancement",
        "The only belief is to invite more people to enjoy the joy by the creative,": "The only belief is to invite more people to enjoy the joy by the creative,",
        "and the comfort by the visual": "and the comfort by the visual",
        "Even a particle can shine up, and it must be!": "Even a particle can shine up, and it must be!",
        "The naming of the “Particle”": "The naming of the “Particle”",
        "Is from one of my name “Huei”": "Is from one of my name “Huei”",
        "“Huei”, the splendor. No grey, no dark.": "“Huei”, the splendor. No grey, no dark.",
        "And “Guan-Tze”, the light, brings energy and movement": "And “Guan-Tze”, the light, brings energy and movement",
        "To carry the thoughts of art": "To carry the thoughts of art",
        "To distribute the evolution and strike by the joyful creativity": "To distribute the evolution and strike by the joyful creativity",
        "To recall us the warmth of the long-forgotten light and color": "To recall us the warmth of the long-forgotten light and color",
        "To bring the limitless possibility, and well-present by any details": "To bring the limitless possibility, and well-present by any details",
        "Particle TW": "Particle TW",
        "Joshua Shiue/J-Wish": "Joshua Shiue/J-Wish",
        "3F., No.253, Deyu Rd., Pingzhen Dist., Taoyuan City 324, Taiwan, Republic of China": "3F., No.253, Deyu Rd., Pingzhen Dist., Taoyuan City 324, Taiwan, Republic of China",
        "Cell phone": "Cell phone",
        "03-428-3302 · 0912-283-302": "03-428-3302 · 0912-283-302",
        "Email": "Mail",
        "particle20081010@gmail.com": "particle20081010@gmail.com",
        "Line": "Line ID",
        "particle20081010": "particle20081010",
        "Facebook": "Facebook",
        "Joshua Shiue/Particle Shiue": "Joshua Shiue/Particle Shiue",
        "Instagram": "Instagram",
        "Joshua_Shiue": "Joshua_Shiue",
        "YouTube": "YouTube",
        // 添加分类类型的翻译
        "All": "All",
        "Graphic Design": "Graphic Design",
        "Brand Design": "Brand Design",
        "Package Design": "Package Design",
        "微光子視覺整合行銷設計" : "Particle TW",

      },
    },
    zh: {
      translations: {
        "Studio Introduction": "工作室介紹",
        "Contact Me": "聯繫我",
        "Praticle Design": "Praticle Design",
        "Particle integrated marketing & graphic design": "微光子視覺整合行銷設計",
        "Joyful creativity, Powerful advancement": "快樂創意．能量進化",
        "Because of the daring wisdom and courage": "藉愚昧的智慧與勇氣",
        "Again, the Particle Design stands up and start off": "再挺身而出並重新再出發",
        "Keep on sparkling, as the meaning of the logo: Joyful creativity, Powerful advancement": "像logo的精神繼續發光:快樂創意．能量進化",
        "The only belief is to invite more people to enjoy the joy by the creative,": "唯一信念就是讓更多人享受無限的快樂創意與舒服的視覺美感,",
        "and the comfort by the visual": "就算微光也能亮，也一定要發亮!!",
        "Even a particle can shine up, and it must be!": "就算微光也能亮，也一定要發亮!!",
        "The naming of the “Particle”": "「粒子」的命名",
        "Is from one of my name “Huei”": "源自我的名字之一「輝」",
        "“Huei”, the splendor. No grey, no dark.": "「輝」，光彩奪目。沒有灰色，沒有黑暗。",
        "And “Guan-Tze”, the light, brings energy and movement": "而「冠澤」，光明，帶來能量與動力",
        "To carry the thoughts of art": "承載藝術的思想",
        "To distribute the evolution and strike by the joyful creativity": "傳遞進化，並以喜悅的創造力打擊",
        "To recall us the warmth of the long-forgotten light and color": "喚起我們對久違光芒與色彩的溫暖記憶",
        "To bring the limitless possibility, and well-present by any details": "帶來無限可能，並在任何細節中完美展現",
        "Particle TW": "Particle TW",
        "Joshua Shiue": "Joshua Shiue",
        "3F., No.253, Deyu Rd., Pingzhen Dist., Taoyuan City 324, Taiwan, Republic of China": "中華民國台灣桃園市平鎮區德育路253號3樓",
        "Cell phone": "手機",
        "03-428-3302 · 0912-283-302": "03-428-3302 · 0912-283-302",
        "Email": "電子郵件",
        "particle20081010@gmail.com": "particle20081010@gmail.com",
        "Line": "台灣連線",
        "particle20081010": "particle20081010",
        "Facebook": "臉書",
        "Joshua Shiue/Particle Shiue": "Joshua Shiue/Particle Shiue",
        "Instagram": "即時電報",
        "Joshua_Shiue": "Joshua_Shiue",
        "YouTube": "優途",
        // 添加分类类型的翻译
        "All": "全部",
        "Graphic Design": "平面設計",
        "Brand Design": "品牌設計",
        "Package Design": "包裝設計",
        "微光子視覺整合行銷設計" : "微光子視覺整合行銷設計",
      },
    },
  },
  lng: userLang, // 使用用户的语言选择
  fallbackLng: "en",
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
  },
  react: {
    wait: true,
  },
});

export default i18n;