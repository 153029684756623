// Header.js
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ThemeToggle from './ThemeToggle';
import { useTranslation } from 'react-i18next';
import { Languages } from 'lucide-react';

const Header = () => {
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('userLang', lng); // 将新的语言保存到 localStorage 中
        setIsDropdownOpen(false); // 关闭下拉菜单
    };

    return (
        <header className='max-w-[1200px] mx-auto flex p-[24px] justify-between'>
            <div className='flex text-[14px] space-x-[24px] text-[#656565] dark:text-[#8d8d8d]'>
                <Link to="/" className={location.pathname === "/" ? "text-[#000000] dark:text-[#e7e7e7] font-medium" : ""}> {t('Studio Introduction')} </Link>
                <Link to="/about" className={location.pathname === "/about" ? "text-[#000000] dark:text-[#e7e7e7] font-medium" : ""}> {t('Contact Me')} </Link>
            </div>
            <div className='flex justify-center items-center space-x-[16px] text-[#000000] dark:text-[#e7e7e7]'>
                <ThemeToggle />
                <div className='relative'>
                    <button onClick={() => setIsDropdownOpen(!isDropdownOpen)} className='flex items-center'>
                        <Languages size={18} />
                    </button>
                    {isDropdownOpen && (
                        <div className='absolute top-[30px] right-0 w-40 dark:bg-[#202020] bg-[#fff] border dark:border-[#343434] border-gray-100 rounded-xl shadow-xl z-50 py-2'>
                            <button onClick={() => changeLanguage('en')} className='block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-[#303030]'>English</button>
                            <button onClick={() => changeLanguage('zh')} className='block w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-[#303030]'>繁体中文</button>
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
}

export default Header;