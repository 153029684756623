// Work.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import getWorksFromAirtable from '../data/airtable'; // 导入 getWorksFromAirtable 函数
import Masonry from 'react-masonry-css';
import Image from './Image'; // 导入 Image 组件

const Work = () => {
    const { t } = useTranslation();
    const [works, setWorks] = useState([]); // 使用 useState 钩子来存储 works
    const [selectedType, setSelectedType] = useState('All'); // 使用 useState 钩子来存储当前选择的分类

    // 固定的分类
    const categories = ['All', 'Graphic Design', 'Brand Design', 'Package Design'];

    useEffect(() => {
        const fetchWorks = async () => {
            const works = await getWorksFromAirtable();
            setWorks(works);
        };

        fetchWorks();
    }, []);

    const handleTypeChange = (type) => {
        setSelectedType(type);
    };

    const filteredWorks = selectedType === 'All' ? works : works.filter(work => work.type === selectedType);

    const breakpointColumnsObj = {
        default: 3,
        1100: 3,
        700: 2,
        500: 1
    };

    if (!works.length) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="loader"></div>
            </div>
        ); // 如果 works 数组为空，显示加载信息
    }

    return (
        <div className="flex flex-col pt-[40px]">
            <div className="mb-4 text-[#686868] dark:text-[#949494] text-[16px] md:text-[32px] font-thin space-x-[6px] md:space-x-[24px] ">
                {categories.map((type, index) => (
                    <button 
                        key={index} 
                        onClick={() => handleTypeChange(type)} 
                        className={`mr-2 ${selectedType === type ? 'text-[#000000] dark:text-[#ffffff] ' : ''}`}
                    >
                        {t(type)}
                    </button>
                ))}
            </div>
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                {filteredWorks.map((work, index) => (
                    <div key={index} className="relative bg-[#ece8e800] mx-[12px] my-[16px] ">
                        {work.images.map((src, imgIndex) => (
                            <Image key={imgIndex} src={src} alt={work.description} />
                        ))}
                    </div>
                ))}
            </Masonry>
        </div>
    );
}

export default Work;